
import {computed, defineComponent, onMounted, ref} from "vue";
import BaseSelect from "../../components/base/select/BaseSelect.vue";
import QuickAction from "../../components/base/action/QuickAction.vue";
import KtDatatable from "../../components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TaskName from "@/views/task/TaskName.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import TaskCreateForm from "@/views/task/TaskCreateForm.vue";
import TaskEditForm from "@/views/task/TaskEditForm.vue";
import {useTaskStore} from "@/store/task.store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {
  getCurrentSpaceAccess,
  GetSpaceId,
  LoadPanel,
  PinaFilterObjects,
  TeamSelect,
  UserSelect
} from "@/core/composite/composite";
import Swal from "sweetalert2";
import TaskService from "@/core/services/TaskService";
import UserListSelect from "@/components/common/UserListSelect.vue";
import UserLink from "@/views/user/UserLink.vue";
import store from "@/store";
import SearchHorizontal from "@/components/common/SearchHorizontal.vue";
import TaskDueDate from "@/views/work/TaskDueDate.vue";
import TeamLink from "@/views/team/TeamLink.vue";
import {useQuickActionStore} from "@/store/quick.action.store";

export default defineComponent({
  name: "AllTasks",
  components: {
    TeamLink,
    TaskDueDate,
    SearchHorizontal,
    UserLink,
    UserListSelect,
    TaskEditForm,
    TaskCreateForm,
    BaseModal, TaskName, DateTimeFormat, KtDatatable, QuickAction, BaseSelect
  },
  setup() {
    const addTaskRef = ref<any>(null);
    const state = ref({
      id: '',
      task: {
        name: '',
        inputs: [],
        results: [],
        description: '',
        assign: 'MY_SELF',
        saveAndStart: false,
        approvalIdRequest: '',
        docIdRequest: ''
      }
    })

    const user = computed(() => store.getters.currentUser);
    const filterObjects = ref({assignedId: '', teamId: '', completed: false, overdue: false, noComplete: true})
    const taskStore = useTaskStore();
    const page = computed(() => taskStore.page)
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("All Tasks",
        [
          {link: false, router: '', text: 'All Tasks'}
        ]
      )
    })
    const headers = ref<any>([])
    const buildHeaders = (isCompleted: boolean) => {
      if (isCompleted) {
        headers.value = [
          {
            name: 'Created',
            key: 'created',
            sortable: false
          },
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
          {
            name: 'Completed',
            key: 'completedDt',
            sortable: false
          },
        ]
      } else {
        headers.value = [
          {
            name: 'Created',
            key: 'created',
            sortable: false
          },
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
        ]
      }
    }
    buildHeaders(false);

    const callback=(action)=> {
      if (action.args[0] === 'TASK') {
        addTaskRef?.value?.click();
      }
    }
    const quickActionStore = useQuickActionStore();
    quickActionStore.$onAction(callback, true);
    return {
      ...TeamSelect(),
      ...GetSpaceId(),
      filterObjects,
      addTaskRef,
      user,
      state,
      page,
      headers,
      ...getCurrentSpaceAccess(),
      buildHeaders,
      ...PinaFilterObjects(taskStore, filterObjects.value, ['owner', 'team']),
      ...LoadPanel(),
      ...UserSelect(),
    }
  },
  methods: {
    searchEvent() {
      if (this.filterObjects.completed) {
        this.buildHeaders(true);
        this.filterObjects.noComplete = false
      } else {
        this.filterObjects.noComplete = true
        this.buildHeaders(false);
      }
      this.updateFilterObject(this.filterObjects);
    },
    discardEvent() {
      this.buildHeaders(false);
      this.filterObjects.assignedId = ''
      this.filterObjects.teamId = ''
      this.filterObjects.completed = false
      this.filterObjects.overdue = false
      this.filterObjects.noComplete = true
      this.updateFilterObject(this.filterObjects);
    },
    onCancelTaskForm() {
      const modal = this.$refs.addTaskModalRef as typeof BaseModal
      modal.hideBaseModal();
    },
    add() {
      const addTaskModal = this.$refs.addTaskModalRef as typeof BaseModal
      this.state.task = {
        name: '',
        inputs: [],
        results: [],
        description: '',
        assign: 'MY_SELF',
        saveAndStart: false,
        approvalIdRequest: this.user.id,
        docIdRequest: '',
      }
      addTaskModal.showBaseModal();
    },
    edit(task: any) {
      this.state.task = {
        name: task.name,
        results: task.resutls,
        inputs: task.inputs,
        description: task.description,
        approvalIdRequest: '',
        docIdRequest: task.docId,
        assign: '',
        saveAndStart: false
      }
      this.state.id = task.id
      const editTaskModal = this.$refs.editTaskModalRef as typeof BaseModal;
      editTaskModal.showBaseModal();
    },
    onCreated() {
      const addTaskModal = this.$refs.addTaskModalRef as typeof BaseModal
      addTaskModal.hideBaseModal();
      this.paginationDataLoad();
    },
    onEdited() {
      const editTaskModal = this.$refs.editTaskModalRef as typeof BaseModal;
      editTaskModal.hideBaseModal();
      this.paginationDataLoad();
    },
    onDeleteEntity(task) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TaskService.delete(task.id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    },
  }
})
